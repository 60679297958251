export enum Genders {
  MALE = 'male',
  FEMALE = 'female',
}

export const BOT_NAME = 'North';

export enum Mood {
  NULL = '',
  SURPRISED = 'surprised',
  INLOVE = 'in love',
  CONFUSED = 'confused',
  PUZZLED = 'puzzled',
  CRAZY = 'crazy',
  SMILING = 'smiling',
  CRYING = 'crying',
  SAD = 'sad',
  NEUTRAL = 'neutral',
  HAPPY = 'happy',
  MAD = 'mad',
  NONE = 'none',
}

export const MONTHS = [
  'None',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MOOD = [
  { name: 'surprised', icon: 'surprised-face.svg' },
  { name: 'in love', icon: 'in-love-face.svg' },
  // { name: 'confused', icon: 'confused-face.svg' },
  { name: 'puzzled', icon: 'puzzled-face.svg' },
  { name: 'crazy', icon: 'crazy-face.svg' },
  { name: 'smiling', icon: 'smiling-face.svg' },
  { name: 'crying', icon: 'crying-face.svg' },
  { name: 'sad', icon: 'sad-face.svg' },
  { name: 'neutral', icon: 'neutral-face.svg' },
  { name: 'happy', icon: 'happy-face.svg' },
  { name: 'mad', icon: 'mad-face.svg' },
];

export const GENDERS = [
  'Male',
  'Female',
  'Non-binary/non-conforming',
  'Prefer not to say',
];

export const IDENTITIES = [
  'Straight',
  'Gay',
  'Lesbian',
  'Bi/Pan',
  'Prefer not to say',
];

export const RELIGIOUS = ['No', 'Yes', 'Prefer not to say'];

export const CHILDRENS = ['No', 'Yes'];

export const RELATIONSHIP_STATUS = [
  'Single',
  'In a relationship',
  'Engaged',
  'Married',
  'Divorced',
  'Widowed',
];

// TODO: update subscription states to match glassfy
export enum SUBSCRIPTION_STATE {
  LITE = 0,
  PLUS_MONTHLY = 1,
  PLUS_YEARLY = 2,
  PRO_MONTHLY = 3,
  PRO_YEARLY = 4,
}

export enum SUBSCRIPTION_PLAN {
  LITE = 0,
  PLUS_MONTHLY = 1,
  PLUS_YEARLY = 2,
  PRO_MONTHLY = 3,
  PRO_YEARLY = 4,
}

export enum AVAILABLE_SKUS {
  PLUS_MONTHLY = 'pmn_plus_monthly_11.95_7days_0',
  PLUS_YEARLY = 'pmn_plus_yearly_115_7days_0',
  PRO_MONTHLY = 'pmn_pro_monthly_29.95_7days_0',
  PRO_YEARLY = 'pmn_pro_yearly_285_7days_0',
}

export enum MOOD_STATS {
  TOTAL = 'total_moods',
  CHAT = 'chat_mood',
  CHECKIN = 'checkin_mood',
}
