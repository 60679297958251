export interface ChatThreadMessage {
  message_id?: number;
  chat_id: number;
  sent_by: SentBy;
  content: string;
  created_at?: Date;
}

export enum SentBy {
  USER = 'USER',
  ASSISTANT = 'ASSISTANT',
  SYSTEM = 'SYSTEM',
}
