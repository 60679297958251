<div class="select-north">
  <h1>Select North model</h1>
  <div class="type-modal" (click)="selectModel('quicker')">
    <h2>North (quicker)</h2>
    <p>
      Fast, friendly, empathetic. Provides concise, reliable information and solutions promptly.
    </p>
  </div>
  <div class="type-modal" (click)="selectModel('wiser')">
    <h2>North (wiser)</h2>
    <p>
      Exceptionally empathetic, creative, thorough. For deeper, compassionate conversations and emotional support.
    </p>
    <p style="font-size: 14px;">Due to advanced abilities, North (wiser) is currently limited to 20 messages per day.</p>
  </div>
</div>
