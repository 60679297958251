import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Platform, ToastController } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private updates: SwUpdate,
    private toastCtrl: ToastController,
    private platform: Platform,
    private router: Router
  ) {
    const keepMeLogged = localStorage.getItem('keepMeLoggedIn');
    if (keepMeLogged === 'false') {
      localStorage.clear();
    }
    this.platform.ready().then(() => {
      if (platform.is('ios'))
        Keyboard.setAccessoryBarVisible({ isVisible: true });
    });
  }
  ngOnInit() {
    // LISTENING TO ROUTING EVENTS
    this.router.events.subscribe((event) => {
      // ON NAVIAGTION END EVENT LOG CURRENT TIME TO LOCAL STORAGE
      if (event instanceof NavigationEnd) {
        localStorage.setItem('logtime', new Date().getTime().toString());
      }
    });

    this.updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          this.toastCtrl
            .create({
              message: 'Downloading new version...',
            })
            .then((toast) => toast.present());
          break;
        case 'VERSION_READY':
          this.toastCtrl
            .create({
              message: 'New version available',
              buttons: [
                {
                  text: 'Reload',
                  handler: () => {
                    window.location.reload();
                  },
                },
              ],
            })
            .then((toast) => toast.present());
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });
  }

  ngOnDestroy() {}
}
